import React from 'react'

const Discounts = () => {
   return (
      <div>
         <h1>Discounts</h1>
      </div>
   )
}

export default Discounts
