import React from 'react'

import { LayoutLoggedIn } from '../components/views/layout'
import Discounts from '../components/views/discounts/discounts'

export default function DiscountsPage() {
   return (
      <LayoutLoggedIn>
         <Discounts />
      </LayoutLoggedIn>
   )
}
